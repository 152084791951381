import React, { Suspense, lazy, Component, createRef } from 'react';
import { NavLink, withRouter, Switch, Route, Redirect } from "react-router-dom";
import { Layout } from 'antd';
import { io } from 'socket.io-client';
import SocketContext from '../../socket-context';
import { setConnected, setPrinters } from '../actions/prints';
import PrintStatus from '../common/PrintStatus';
import Drawer from "../common/Drawer"
import BreadNav from "../common/Breadcrumb"
import TopMenu from "../common/TopMenu";
import Main from '../Dashboard';
import NotFound from "../common/NotFound";
import { connect } from "react-redux";
import TopLoadingComponent  from "../common/TopLoader";
import axios from 'axios';

const { Header, Content, Footer } = Layout;

const version = require('../../../package.json').version;
class MainLayout extends Component {

  constructor(props){
    super(props)
    this.state = {
      currentMenu: [],
      breadcump: [],
      menu: null,
      mounted: false,
      isMobile: false
    }
    this.socket = createRef()
  }

  getMenu(isAuth, user){
   
    if (isAuth && this.state.menu === null) {
     
      axios.get(`/api/v1/menu/${user}`).then(res => {
        if (res.data) this.setState({ menu: res.data }, ()=>this.search(res.data , window.location.pathname));
      });
    }
  }


  search = (menu, searchkey) => {   
   
    let obj = menu.find(o => (o.url === searchkey && o.path));
    
    if (obj){
      let openkeys = []
      let breadcump = []
      openkeys.push(obj._id)
      breadcump.push({icon: obj.icon, url: obj.url, text: obj.text})
      
      obj['parentnodes'].sort((a, b) => parseFloat(b.order) - parseFloat(a.order)).forEach(element => {
        openkeys.push(element._id)
        breadcump.push({icon: element.icon, url: element.url, text: element.text})
      });
      
      this.setState({currentMenu: {
        menuKeys: [obj._id],
        openKeys: openkeys,
      }, breadcump, mounted: true})
    } else {
      let obj = searchkey.split('/')
      obj.shift()
      let breadcump = []
      obj.forEach((element, i) => {
        if (i === obj.length-1) breadcump.push({text: element, url: searchkey})
        else breadcump.push({text: element})
      });
      let currentMenu = (searchkey === '/') ? { menuKeys: ['dashboard'], openKeys: ['dashboard']} : {menuKeys: [], openKeys: []}
      this.setState({breadcump: breadcump.reverse(), currentMenu, mounted: true })
    }
  }

  handleResize = () => {
    if (window.innerWidth <= 1024 && this.state.isMobile === false ) this.setState({ isMobile: true });
    else if (window.innerWidth > 1024 && this.state.isMobile === true) this.setState({ isMobile: false });
  }

  componentDidMount(){ 
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    if (this.props.auth.isAuthenticated) {
      this.getMenu(this.props.auth.isAuthenticated, this.props.auth.user.id) 
      if (!this.props.auth.user.company) {
        this.socket.current = io('http://localhost:2010', {reconnectionAttempts: 40});
        this.socket.current.on('connect', () => {
          console.log('connected')
          this.socket.current.timeout(5000).emit('listPrinters', null, (err, result) =>{
            this.props.setPrinters(result)
          })
          this.props.setConnected(true)
        });

        this.socket.current.on('disconnect', () => {
          this.props.setConnected(false)
        })

        this.socket.current.io.on('error', function(err) {
          // handle server error here
          console.log('Error connecting to server');
        });
      }
    } else {
      this.setState({mounted: true})
      this.props.setConnected(false)
    }
  }

  componentWillUnmount(){
    this.props.setConnected(false)
    window.removeEventListener('resize', this.handleResize)
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    
    if (nextProps.auth.isAuthenticated){
      this.getMenu(true, nextProps.auth.user.id) 
    } else {
      this.props.history.push('/login')
    }
  }

  setCurrent = (current)=>{
    this.search(this.state.menu, current)
  }

  render() {
    
    if (!this.state.mounted) {
      return <TopLoadingComponent />;
    }
    
    const isAuthenticated = this.props.auth.isAuthenticated
    const user = this.props.auth.user

    const token = localStorage.getItem('key')
    if (isAuthenticated && token) { axios.defaults.headers.common['Authorization'] = token; }
    else { 
        delete axios.defaults.headers.common['Authorization'];  
    if (this.props.location && this.props.location.pathname !== '/login' && this.props.location.pathname !== '/signup') return <Redirect push to= '/login' />  }
    
    const {menu, isMobile} = this.state
    const loadedRoutes = menu
    return (
      
      <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}> 
        <SocketContext.Provider value={this.socket.current}>
          <Drawer menu={this.state.menu} current={this.state.currentMenu} setCurrent={this.setCurrent}/>
        
          <Layout>
            <Header style={{ background: '#fff', padding: 0,display: 'flex', justifyContent: 'center', alignitems: 'center' }} >
              <NavLink activeStyle={{ color: "black", display: 'flex', justifyContent: 'center', alignItems: 'center' }} to="/">
                <div className="logo" style={{borderRight: isMobile ? 'unset' : '1px solid lightgrey'}}>TransWeb <span className="title_version">7</span></div>
              </NavLink>
              {!isMobile && <BreadNav current={this.state.breadcump}/>}
              <div style={{display: 'flex', justifyContent:'flex-end', flex: 1, alignItems: 'center'}}>
              {(user && user.company) && <div style={{display: 'flex', fontSize: 12, lineHeight: '12px', verticalAlign: 'middle', marginRight: 20, float: 'right'}}>{user.company.name}</div>}
                {(user && !user.company) && <div style={{marginRight: 20}}><PrintStatus /></div>}
              </div>
              <TopMenu />
            </Header>
            <Content style={{
              margin: '16px 5px 0px 5px', padding: '15px', background: '#fff', minHeight: 280,
            }}
            > 
              {isMobile && <BreadNav current={this.state.breadcump}/>}
              <Layout className="mainLayout">
                <Switch>
                  <Route path="/" exact render={props => 
                    isAuthenticated ? (
                    <Suspense fallback={props.error ?  <ErrorComponent {...props}/> : <TopLoadingComponent/>}>
                      <Main {...props}/>
                    </Suspense>
                    ) :  <Redirect to= '/login' />
                  }/>
                  { (loadedRoutes) && loadedRoutes.map(function(props, index){
                    if(props.path && props.component) {
                        props['component'] = lazy(() => import(/* webpackChunkName: "[request]" */`../${props.path}`));
                        const { component: Component, ...rest } = props
                        var perms = {}
                        props.permissions && props.permissions.forEach(element => {
                          perms[element.value] = true
                        });
                        
                        return  (<Route key={index} exact={props.exact || true} {...rest} path={props.url}  render={props => 
                          isAuthenticated ? (
                            <Suspense fallback={props.error ?  <ErrorComponent {...props}/> : <TopLoadingComponent/>}>
                              <Component permissions={perms} {...props}/> 
                            </Suspense>
                          ) :  <Redirect to= '/login' />
                        }/>)
                    }
                  })}    
                  <Route component= {NotFound} /> 
                  </Switch>
              </Layout>
            </Content>
            <Footer style={{ textAlign: 'right', paddingTop: '0px', paddingRight: '17px', height: '10px' }}>
              v. {version}
            </Footer>
          </Layout>
        </SocketContext.Provider>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => ({
  setConnected: value => dispatch(setConnected(value)),
  setPrinters: value => dispatch(setPrinters(value)),
})
  
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainLayout));