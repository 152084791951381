import React from 'react';
import { Breadcrumb, Icon } from 'antd';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getTranslate } from 'react-localize-redux';
class BreadNav extends React.Component {

  state = { 
    mounted: false,
    current: null
  }

  componentDidMount() {
    this.setState({ mounted: true })
  }

  UNSAFE_componentWillReceiveProps(){
    this.setState({current: this.props.current.reverse()})
  }

  render() {
    if (!this.state.mounted) {
      return <></>;
    }
    const { translate } = this.props

    const current = this.props.current.reverse()
    
    return (
        <Breadcrumb style={{marginLeft: '20px', display: 'flex', alignItems: 'center'}}>
          { (this.props.location.pathname !== "/") && (
          <Breadcrumb.Item>
            <Link to="/">
              <Icon type="home" />
            </Link>
          </Breadcrumb.Item>
        )} 
        {(current) && current.map(function(props, index){
          
          return (
            (props.url) ? (
              <Breadcrumb.Item key={index}>
                <Link className="ant-breadcrumb-link" to={props.url ? props.url : ""}>
                {props.icon && <Icon type={props.icon} />}
                {(props.text && props.url !== '/') && <span>{translate(props.text)}</span>}
                </Link>
              </Breadcrumb.Item>
            ): (
              <Breadcrumb.Item key={index}>
               { props.text && translate(props.text) }
              </Breadcrumb.Item>
            )        
          )
        }
        )}
      </Breadcrumb>
    )
  }

}

const mapStateToProps = state => {
  return {
    translate: getTranslate(state.localize)
  };
};

export default connect(mapStateToProps)(withRouter(BreadNav));