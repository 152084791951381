import { Icon } from 'antd';
import { useSelector } from 'react-redux';

const PrintStatus = () => {

  const connected = useSelector(state => state.printSystem.isConnected)

  return (
    <Icon type="printer" theme="twoTone" twoToneColor={connected ? '#0066b2' : 'lightgray'} />
  )
}

export default PrintStatus