import { combineReducers } from 'redux';
import flashMessages from './components/reducers/flashMessages';
import errorReducer from './components/reducers/errorReducer';
import authReducer from './components/reducers/authReducer';
import printReducer from './components/reducers/printReducer';
import { localizeReducer as localize } from 'react-localize-redux';

export default combineReducers({
  flashMessages,
  errors: errorReducer,
  auth: authReducer,
  printSystem: printReducer,
  localize
});