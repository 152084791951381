import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducer";
import checkTokenExpiration from './components/middlewares/checkTokenExpiration';
import axiosRedirect from './components/middlewares/axiosRedirect';


let enhancer;


if (process.env.NODE_ENV !== 'production' ) {

  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
      applyMiddleware(thunk, checkTokenExpiration, axiosRedirect)
    );
  } else {
    enhancer = compose(
      applyMiddleware(thunk, checkTokenExpiration, axiosRedirect),
      DevTools.instrument(),
      persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/))
    );
  }
} else {
  enhancer = compose(
    applyMiddleware(thunk, checkTokenExpiration, axiosRedirect)
  );

}

export default createStore(
    rootReducer,
    enhancer
)