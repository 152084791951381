import jwt_decode from 'jwt-decode';
import { logoutUser } from '../actions/authentication';
import SetInterval from 'set-interval'

const checkTokenExpiration = store => next => action => {
    const token = localStorage.getItem("key") ? localStorage.getItem("key") : '';
 
    if (token) {
        SetInterval.start(()=>{ 
            const token2 = localStorage.getItem("key") ? localStorage.getItem("key") : '';
            if (jwt_decode(token).exp < Date.now() / 1000) {
                store.dispatch(logoutUser());
            }
            if(!token2) {
                store.dispatch(logoutUser());
            }
            
        }, 1000, 'checkjwt')
        next(action);
    } else {
        next(action);
        SetInterval.clear('checkjwt')
    }
};



export default  checkTokenExpiration;