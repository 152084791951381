import React from "react";

const LoadingComponent = () => {
  return (
    <div style={{textAlign:"center"}}>
        Loading...<img src="/static/img/loader.gif" style={{height: "20px", marginLeft: "5px", borderRadius: '50%'}} />
    </div>
  );
};


export default LoadingComponent;