import React from 'react';
import { Modal, Form, Input } from 'antd';
import { connect } from "react-redux";
import { getTranslate } from 'react-localize-redux';

const UserForm = Form.create({ name: 'userform', mapPropsToFields(props) {
  return {
    name: Form.createFormField({
      ...props.name,
      value: props.auth.user.name,
    }),
  };
}, })(
  class extends React.Component {

    state = {
      confirmDirty: false,
      checkPass: false,
      checkPassConf: false,
    };

    handleConfirmBlur = (e) => {
      const value = e.target.value;
      this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    }

    compareToFirstPassword = (rule, value, callback) => {
      const {form, translate } = this.props;
      if (value && value !== form.getFieldValue('password')) {
        callback(`${translate("userForm.passwordNotmatch")} !`);
      } else {
        callback();
      }
    }

    validateToNextPassword = (rule, value, callback) => {
      const form = this.props.form;
      if (value && this.state.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    }

    handlePasswordChange = (e) => {
      
      this.setState({
        checkPassConf: e.target.value ? true : false,
      }, () => {
        this.props.form.validateFields(['confirm'], { force: true });
      });
    
    }

    handleConfirmChange = (e) => { 
      this.setState({
        checkPass: e.target.value ? true : false,
      }, () => {
        this.props.form.validateFields(['password'], { force: true });
      });
    }

    render() {
      const { visible, onCancel, onCreate, form, translate, auth} = this.props;
      if (!visible) return null;
      const { getFieldDecorator } = form;
      const company = auth.user.company ? auth.user.company.name : null
      
      return (
        <Modal
          visible={visible}
          title={translate('userForm.title')}
          okText={translate('global.save')}
          cancelText={translate('global.cancel')}
          onCancel={onCancel}
          onOk={onCreate}
        >
          <Form layout="vertical">
            {company && <Form.Item label={translate('userForm.company')}>
              <Input defaultValue={company} disabled />
            </Form.Item>}
            <Form.Item label={translate('userForm.name')}>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: `${translate("userForm.emptyName")} !` }],
            })(
                <Input />
            )}
            </Form.Item>
            <Form.Item label={translate('userForm.password')}>
              {getFieldDecorator('password', {
                rules: [{
                  required: this.state.checkPass, message: `${translate("userForm.emptyPass")} !`,
                }, {
                  validator: this.validateToNextPassword,
                }],
              })(
                <Input type="password" onChange={this.handlePasswordChange}  ref={(ref) => ref.focus()}/>
              )}
            </Form.Item>
            <Form.Item label={translate('userForm.confirmPassword')}>
              {getFieldDecorator('confirm', {
                rules: [{
                  required: this.state.checkPassConf, message: `${translate("userForm.emptyConfirmPass")} !`,
                }, {
                  validator: this.compareToFirstPassword,
                }],
              })(
                <Input type="password" onBlur={this.handleConfirmBlur} onChange={this.handleConfirmChange} />
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    translate: getTranslate(state.localize)
  };
};

export default connect(mapStateToProps)(UserForm);
