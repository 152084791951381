import axios from "axios";
const apiv1 = '/api/v1';

export default {
  menu: {
    list: filter => axios.get(`${apiv1}/menu`, {params: filter}),
    insert: (data) => axios.post(`${apiv1}/menu`,data),
    update: (data) => axios.put(`${apiv1}/menu`,data),
    delete: (id) => axios.delete(`${apiv1}/menu/${id}`)
  },
  menuPermissions: {
    list: filter => axios.get(`${apiv1}/permissions`, {params: filter}),
    insert: (data) => axios.post(`${apiv1}/permissions`,data),
    update: (data) => axios.put(`${apiv1}/permissions/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/permissions/${id}`)
  },
  users: {
    list: () => axios.get(`${apiv1}/users/`),
    insert: (data) => axios.post(`${apiv1}/users`,data),
    update: (data) => axios.put(`${apiv1}/users/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/users/${id}`)
  },
  groups: {
    list: () => axios.get(`${apiv1}/groups/`),
    findOne: (id) => axios.get(`${apiv1}/groups/${id}`),
    insert: (data) => axios.post(`${apiv1}/groups`,data),
    update: (data) => axios.put(`${apiv1}/groups/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/groups/${id}`),
    updatemenu: (data) => axios.put(`${apiv1}/groupsMenu/${data._id}`,data),
  },
  calendar: {
    list: filter => axios.get(`${apiv1}/calendar`, {params: filter}),
    findOne: (id) => axios.get(`${apiv1}/calendar/${id}`),
    insert: (data) => axios.post(`${apiv1}/calendar`,data),
    update: (data) => axios.put(`${apiv1}/calendar/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/calendar/${id}`)
  },
  visitors: {
    list: filter => axios.get(`${apiv1}/libraries/visitors`, {params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/visitors/${id}`),
    insert: (data) => axios.post(`${apiv1}/libraries/visitors`,data),
    update: (data) => axios.put(`${apiv1}/libraries/visitors/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/libraries/visitors/${id}`)
  },
  oximata: {
    list: filter => axios.get(`${apiv1}/libraries/oximata`, {params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/oximata/${id}`),
    insert: (data) => axios.post(`${apiv1}/libraries/oximata`,data),
    update: (data) => axios.put(`${apiv1}/libraries/oximata/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/libraries/oximata/${id}`)
  },
  destinations: {
    list: filter => axios.get(`${apiv1}/libraries/destinations`, {params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/destinations/${id}`),
    insert: (data) => axios.post(`${apiv1}/libraries/destinations`,data),
    update: (data) => axios.put(`${apiv1}/libraries/destinations/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/libraries/destinations/${id}`)
  },
  periektes: {
    list: filter => axios.get(`${apiv1}/libraries/periektes`,{params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/periektes/${id}`),
    insert: (data) => axios.post(`${apiv1}/libraries/periektes`,data),
    update: (data) => axios.put(`${apiv1}/libraries/periektes/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/libraries/periektes/${id}`)
  },
  skopoi: {
    list: filter => axios.get(`${apiv1}/libraries/skopoi`, {params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/skopoi/${id}`),
    insert: (data) => axios.post(`${apiv1}/libraries/skopoi`,data),
    update: (data) => axios.put(`${apiv1}/libraries/skopoi/${data._id}`, data),
    delete: (id) => axios.delete(`${apiv1}/libraries/skopoi/${id}`)
  },
  arrivals: {
    active: filter => axios.get(`${apiv1}/warehouse/arrivals/active`, { params: filter}),
    findOne: (id) => axios.get(`${apiv1}/warehouse/arrivals/${id}`),
    insert: (data) => axios.post(`${apiv1}/warehouse/arrivals`,data),
    update: (id, data) => axios.put(`${apiv1}/warehouse/arrivals/${id}`, data),
    list: filter => axios.get(`${apiv1}/warehouse/arrivals/`, { params: filter}),
    getRows: (id) => axios.get(`${apiv1}/warehouse/arrivals/rows/${id}`),
  },
  vessels: {
    list: filter => axios.get(`${apiv1}/warehouse/vessels`, { params: filter}),
    findOne: (id) => axios.get(`${apiv1}/warehouse/vessels/${id}`),
    insert: (data) => axios.post(`${apiv1}/warehouse/vessels`,data),
    update: (data) => axios.put(`${apiv1}/warehouse/vessels/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/warehouse/vessels/${id}`)
  },
  cities: {
    list: filter => axios.get(`${apiv1}/libraries/cities`, { params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/cities/${id}`),
    insert: (data) => axios.post(`${apiv1}/libraries/cities`,data),
    update: (data) => axios.put(`${apiv1}/libraries/cities/${data._id}`,data)
  },
  doys: {
    list: filter => axios.get(`${apiv1}/libraries/doys`, { params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/doys/${id}`),
  },
  countries: {
    list: filter => axios.get(`${apiv1}/libraries/countries`, { params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/countries/${id}`),
  },
  companies: {
    list: filter => axios.get(`${apiv1}/libraries/companies`, {params: filter}),
    search: filter => axios.get(`${apiv1}/libraries/companies/gsis`, {params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/companies/${id}`),
    insert: (data) => axios.post(`${apiv1}/libraries/companies`,data),
    update: (data) => axios.put(`${apiv1}/libraries/companies/${data._id}`,data)
  },
  packings: {
    list: filter => axios.get(`${apiv1}/libraries/packings`, {params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/packings/${id}`),
    insert: (data) => axios.post(`${apiv1}/libraries/packings`,data),
    update: (data) => axios.put(`${apiv1}/libraries/packings/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/libraries/packings/${id}`)
  },
  packages: {
    list: filter => axios.get(`${apiv1}/libraries/packages`, {params: filter}),
    findOne: (id) => axios.get(`${apiv1}/libraries/packages/${id}`),
    insert: (data) => axios.post(`${apiv1}/libraries/packages`,data),
    update: (data) => axios.put(`${apiv1}/libraries/packages/${data._id}`,data),
    delete: (id) => axios.delete(`${apiv1}/libraries/packages/${id}`)
  },
  movements: {
    list: filter => axios.get(`${apiv1}/warehouse/movements`, {params: filter}),
    exportItems: filter => axios.get(`${apiv1}/warehouse/movements/exportItems`, {params: filter}),
    exportItemsEdit: filter => axios.get(`${apiv1}/warehouse/movements/exportItemsEdit`, {params: filter}),
    getEditRecordInfo: filter => axios.get(`${apiv1}/warehouse/movements/getEditRecordInfo`, {params: filter}),
    exportItemsGetById: ids => axios.get(`${apiv1}/warehouse/movements/findExportItemByIds`, {params: { ids }}),
    stock: filter => axios.get(`${apiv1}/warehouse/movements/stock`, {params: filter}),
    exportItemsFinal: data => axios.post(`${apiv1}/warehouse/export`, data),
    exportUpdateItem: data => axios.post(`${apiv1}/warehouse/export/updateOne`, data),
    cancelExportItem: data => axios.post(`${apiv1}/warehouse/export/cancelExport`, data),
    imports: filter => axios.get(`${apiv1}/warehouse/imports`, {params: filter}),
    importFinal: data => axios.post(`${apiv1}/warehouse/import`, data),
    importRows: (id) => axios.get(`${apiv1}/warehouse/imports/${id}`),
    importExcel: data => axios.post(`${apiv1}/warehouse/imports/excel`, data),
    delete: (id) => axios.delete(`${apiv1}/warehouse/imports/${id}`)
  },
  reports: {
    list: (data) => axios.get(`${apiv1}/reports${data}`),
    download: (params) => axios.get(`${apiv1}/reports/printReport`, { params, responseType: 'blob' }),
    getReport: params => axios.get(`${apiv1}/reports/printReport`, { params, responseType: 'arraybuffer'}),
    insert: (data) => axios.post(`${apiv1}/reports`,data),
    saveReport: (reportId, data) => axios.post(`${apiv1}/reports/save/${reportId}`, data),
    update: (data) => axios.patch(`${apiv1}/reports/${data._id}`, data),
    delete: (id) => axios.delete(`${apiv1}/reports/${id}`),
    getReportData: (name, params) =>  axios.get(`${apiv1}/reports/getReportData/${name}`, { params }),
  },
}