import axios  from 'axios';
import { logoutUser } from '../actions/authentication';
// import { UNAUTH_USER } from '../actions/types';


const axiosRedirect = store => next => action => {
    const token = localStorage.getItem('key')
    if (token) { axios.defaults.headers.common['Authorization'] = token; }
    else { delete axios.defaults.headers.common['Authorization']; }

    axios.interceptors.response.use( response => response,  
        error => {
        //catches if the session ended!
        if ( error.response?.status === 401) {
            store.dispatch(logoutUser())
        } 
        return error;
    });
    next(action);
};



export default  axiosRedirect;