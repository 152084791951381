import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from "../actions/authentication";
import Form from 'devextreme-react/form';
import LoadingComponent  from "../common/Loader";
import { notification } from 'antd';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.form = null;

    this.state = {
      errors: {},
      loaded: false,
      formItems : [{
        dataField: 'email',
        label: {text: 'Username / Email'},
        editorOptions: {
          stylingMode: "underlined"
        },
        validationRules: [{
          type: "required",
          message: "Username is required"
        }]
      }, {
        dataField: 'password',
        label: {text: 'Password'},
        editorOptions: {
          mode: "password",
          stylingMode: "underlined",
          onKeyUp: this.onKeyUp
        },
        validationRules: [{
          type: "required",
          message: "Password is required"
        }]
      },{
        itemType: "button",
        buttonOptions: {
          text: "Login",
          width: "100%",
          height: 40,
          type: "default",
          useSubmitBehavior: true,
          onClick: this.onClick
        }
      }]
    };

  }

  setForm = ref => {
   if (ref && ref.instance) this.form = ref.instance;
  }

  onClick = (e)=> {
    const isValid = this.form.validate().isValid;
    if (isValid) {
      this.props.loginUser(this.form.option('formData'));
    }
  }

  onKeyUp = (e)=>{
    (e.event.keyCode === 13) && this.onClick()
  }

  checkErrors(){
    if (typeof this.state.errors  === 'object' &&  Object.entries(this.state.errors) > 0) {
      notification.error({
        message: this.state.errors.field,
        description: this.state.errors.message,
        onClose: ()=> this.setState({errors: {}})
      }) 
    }
  }

  componentDidMount() {
    if(this.props.auth.isAuthenticated) {
      this.props.history.push('/');
    } 
    this.setState({loaded: true})
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.auth.isAuthenticated) {
      this.props.history.push('/')   
    } 
    else if(nextProps.errors) {
      if (typeof nextProps.errors  === 'object' && Object.entries(nextProps.errors).length > 0) {
        notification.error({
          message: nextProps.errors.field,
          description: nextProps.errors.message
        })
        this.setState({ errors: nextProps.errors },()=>this.checkErrors());
      } else {
        this.setState({ errors: {} });
      }
    }
  }

  shouldComponentUpdate(nexrProps){
    if (!nexrProps.auth.isAuthenticated) return true
    else return false
  }

  render() {
    const { loaded } = this.state;

    if (!loaded) {
      return <LoadingComponent />;
    }

    return (
      <>
        <span className="login-form-title p-b-20">
          <div className="logo" style={{width: 'auto'}}>TransWeb <span className="title_version">7</span></div>
          <span style={{width: '100%', fontSize: 14}}>Port Operation</span>
        </span>
        <span className="login-form-subtitle p-b-53">Login</span>
        <Form 
          ref={this.setForm}
          labelLocation="top"
          showColonAfterLabel={false}
          items={this.state.formItems} 
          showRequiredMark={false}
          validationGroup="form"
          elementAttr={{onSubmit:this.onSubmit}}
        />
      </>
    );
  }
}

LoginPage.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
})

export  default connect(mapStateToProps, { loginUser })(LoginPage)
