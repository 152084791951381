import React from 'react';
import { Layout } from 'antd';
import { withRouter } from "react-router-dom";
import TopLoadingComponent  from "./TopLoader";
import DrawerMenu from './DrawerMenu'

const { Sider } = Layout;


const toBoolean = (value)=>{
  return (value == 'true') ? true
    : (value == 'false') ? false
    : null;
}

class DrawerSider extends React.PureComponent {

  state = {
    mounted: false,
    isMobile: false,
    collapsed: toBoolean(localStorage.getItem("collapsed")) || (window.innerWidth <= 1024) ? true : false
  }

  onCollapse = (collapsed, action) => {
    if(this.state.mounted && action === 'clickTrigger' ){
      (!this.state.isMobile) && localStorage.setItem("collapsed", collapsed);
      this.setState({collapsed})
    }
  }

  UNSAFE_componentWillMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    this.setState({ mounted: true})
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => {
    if (window.innerWidth <= 1024 && this.state.isMobile === false ) this.setState({ isMobile: true });
    else if (window.innerWidth > 1024 && this.state.isMobile === true) this.setState({ isMobile: false });
  }

  setCollapsed = (collapsed) => this.setState({collapsed})


  render() {
    if (!this.state.mounted) {
      return <TopLoadingComponent />;
    }
   
    return (
      <Sider
        width={256}
        {...(this.state.isMobile && {
          breakpoint:"lg",
          collapsedWidth:"0"
        })}
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
      >   
        <DrawerMenu collapsed={this.state.collapsed} isMobile={this.state.isMobile} setCurrent={this.props.setCurrent} setCollapsed={this.setCollapsed} {...this.props}/>
      </Sider>
    );
  }
}


  
export default withRouter(DrawerSider);