import React from 'react';
import { Menu, Icon, Select } from 'antd';
import { NavLink, withRouter } from "react-router-dom";
import { getActiveLanguage, setActiveLanguage, getTranslate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser, setCurrentUser }  from '../actions/authentication';
import { locale } from "devextreme/localization";
import UserForm from "./Profile"
import jwt_decode from 'jwt-decode';
import notify from 'devextreme/ui/notify';
import api from '../api'

const provinceData = [{id:"en",text:"En"}, {id:"el", text:"Gr"}];
const Option = Select.Option;

const SubMenu = Menu.SubMenu;

class TopMenu extends React.Component {

  state = {
    visible: false
  }

  handleClick = (e) => {
    if (e.key === "logout") this.props.logoutUser();
    if (e.key === "profile") this.showModal()
  }

  handleChange = (value)=>{
    this.props.setActiveLanguage(value)
    locale(value)
    localStorage.setItem('locale',value)
    //document.location.reload();
  }

  showModal = () => {
    this.setState({ visible: true });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      var data = Object.assign({}, values)
      if (data.company) delete data.company;
      delete data.confirm;
      if (data.password === undefined) {
        delete data.password;
      }
      data._id = this.props.auth.user.id;

      api.users.update({...data})
      .then((response)=> {
        
        if (response.data) {
          const { token } = response.data;
          localStorage.setItem('key', token);
          const decoded = jwt_decode(token);
          this.props.setCurrentUser(decoded);

          form.resetFields();
          this.setState({ visible: false });
        } 
      })
      .catch((err) => notify({
        message: this.props.translate(err.response.data.error.message),
      }, 'error', 3000))

      
    });
  }
  
  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  
  render() {

    const {isAuthenticated} = this.props.auth;
    const { currentLanguage, translate } = this.props
   
    const authLinks = (
        
      <Menu
        onClick={this.handleClick}
        mode="horizontal"
        style={{ lineHeight: '64px', textAlign: 'right', borderBottom: 0 }}  
      >
        <Menu.Item disabled key="languageSelect" style={{padding: '0px'}}>
          <Select
            defaultValue={currentLanguage}
            size='large'
            showArrow={false}
            onChange={this.handleChange}
            >
            {provinceData.map(province => <Option key={province.id}>{province.text}</Option>)}
          </Select>
        </Menu.Item>
        
        <SubMenu key="user" title={<span><Icon type="user" /></span>}>
          <Menu.Item key="profile"><Icon type="edit" />{translate('global.profile')}</Menu.Item>
          <Menu.Item key="logout"><Icon type="poweroff" />{translate('global.logout')}</Menu.Item>
        </SubMenu>
        <UserForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />
      </Menu>
    )
    const guestLinks = (
      <Menu
        onClick={this.handleClick}
        mode="horizontal"
        style={{ lineHeight: '64px', float: 'right' }}
      >
      <Menu.Item key="/login">
      <NavLink to="/login">
        Login
      </NavLink>
      </Menu.Item>
      </Menu>
    )

    return isAuthenticated ? authLinks : guestLinks
            
  }

}

TopMenu.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  }
  
const mapStateToProps = (state) => ({
  auth: state.auth,
  currentLanguage: getActiveLanguage(state.localize).code,
  translate: getTranslate(state.localize)
})

export default connect(mapStateToProps, { logoutUser, setActiveLanguage, getTranslate, setCurrentUser })(withRouter(TopMenu));