import React, { Component } from "react";
import LoadingComponent  from "../common/Loader";
import { connect } from "react-redux";
import { getTranslate } from 'react-localize-redux';

import { Row, Col } from 'antd';

class Dashboard extends Component{

  state = {
    loaded: false
  }

  componentDidMount() {
    this.setState({loaded: true});
  }

  render() {

    if (!this.state.loaded) {
      return <LoadingComponent />;
    }
  
    return (
      <Row style={{textAlign:'center'}}>
        <Col xs={24}>
          <div style={{lineHeight: '65px'}}>
            <span style={{float: 'none', fontSize: 40 , color: 'grey',}}>TransWeb </span>
            <span className="title_version" style={{verticalAlign: 'text-bottom', fontSize: 20}}>7</span>
          </div>
        </Col>
        <Col xs={24} style={{marginTop: 20}}>
          <div style={{fontSize: 20}}>Dashboard</div>
        </Col>
      </Row>
    );
  }
}



const mapStateToProps = state => {
  return {
    translate: getTranslate(state.localize)
  };
};

export default connect(mapStateToProps)(Dashboard);