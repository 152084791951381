import React from "react";
import TopBarProgress from "react-topbar-progress-indicator"

TopBarProgress.config({
  barColors: {
    "0": "#fff",
    "1.0": "#fff",
  },
  shadowBlur: 3,
})

const TopLoadingComponent = () => {
  return <TopBarProgress />;
};


export default TopLoadingComponent;