
import { SET_PRINTSYSTEM_CONNECTED, SET_PRINTTERS } from './types';

export const setConnected = value => {
  return {
    type: SET_PRINTSYSTEM_CONNECTED,
    payload: value
  }
}

export const setPrinters = value => {
  return {
    type: SET_PRINTTERS,
    payload: value
  }
}
