import React from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import { LocalizeProvider } from 'react-localize-redux';
import App from './components/App';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import './components/styles/devextreme-override.css';
import "./app.css";
import "./devextreme.css";
import jwt_decode from 'jwt-decode';
import { setCurrentUser, logoutUser } from './components/actions/authentication';
import configDefault from "devextreme/core/config";
import * as serviceWorker from './registerServiceWorker';
import store from './store'

configDefault({
  defaultCurrency: "EUR",
  editorStylingMode: "underlined"
});


// Dictionaries for German and Russian languages
import elMessages from "devextreme/localization/messages/el.json";
import { locale, loadMessages } from "devextreme/localization";

loadMessages(elMessages);
locale(localStorage.getItem('locale') || 'el')
// locale(navigator.language || navigator.browserLanguage)


if(localStorage.getItem('key')) {
    
  try {
   
    const decoded = jwt_decode(localStorage.getItem('key'));
    store.dispatch(setCurrentUser(decoded));
    const currentTime = Date.now() / 1000;
    if(decoded.exp < currentTime) {
      store.dispatch(logoutUser());
      // window.location.href = '/login'
    }
    
  } catch(error) {
    if(error) localStorage.removeItem('key')
  }
    
}

const container = document.getElementById('app');
const root = createRoot(container)

root.render(<Provider store={store}><LocalizeProvider store={store}><App /></LocalizeProvider></Provider>);
serviceWorker.unregister()
