import React from 'react';
import { Menu, Icon } from 'antd';
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getTranslate } from 'react-localize-redux';
import LoadingComponent  from "./Loader";


const SubMenu = Menu.SubMenu;

const SubMenuWrapper = ({ children, mykey,...props }) => 
  React.Children.map(children, (child, i) =>
    React.cloneElement(child, {
      ...props,
      eventKey: mykey,
      //subMenuKey: `${i}-menu-`,
      subMenuKey: mykey
    })
  );


const SubMenuItems = ({items, translate, ...other}) =>{
  items.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
  return (items) ? items.map((props, index)=>{
   let {level, parent} = {...other}
   if (props.parent === parent){
   
    if ( !props.url && (props.subnodes && props.subnodes.length >= 0))  {  
      return (
        <SubMenuWrapper key={props._id} mykey={props._id}>
          <SubMenu {...other} key={props._id} title={<span><Icon type={props.icon} /><span>{translate(props.text)}</span></span>}>
            <SubMenuItems items={props.subnodes} translate={translate} parent={props._id} level={level + 1}/>
          </SubMenu>
        </SubMenuWrapper>
      )} 

    else if ( props.url && (!props.subnodes || props.subnodes.length === 0) && ((props.parentnodes && props.parentnodes.length !== level) || !props.parentnodes))  { 
    
      return (
        <SubMenuWrapper key={props._id} mykey={props._id}>
          <Menu.Item {...other} >
            <Link to={props.url} >
                <Icon type={props.icon} />
                <span>{translate(props.text)}</span>
            </Link>
          </Menu.Item>
        </SubMenuWrapper>
      );
      
    }}
  }) : <></>;

}


class DrawerMenu extends React.Component {

  state = {
    mounted: false,
    current: undefined
  }

  selectionChange = ({ item, key, selectedKeys }) => {
    if (this.props.isMobile){
      this.props.setCollapsed(true)
      this.props.current.menuKeys = selectedKeys
      this.props.current.openKeys = selectedKeys
    }
    
    this.props.setCurrent(window.location.pathname)
  }


  UNSAFE_componentWillReceiveProps(nextProps){
    this.setState({current:nextProps.current.openKeys})
  }

  componentDidMount(){
    this.setState({mounted:true})
  }

  render() {
    if (!this.state.mounted) {
      return <LoadingComponent />;
    }
    
    const { menu, translate } = this.props;
    menu && menu.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))

    return (
      
      <Menu 
        defaultSelectedKeys={this.props.current.menuKeys}     
        {...(this.props.collapsed !== true && {
          defaultOpenKeys: this.state.current || this.props.current.openKeys
        })}
        mode="inline"
        onSelect={this.selectionChange}
        theme="dark"
      >
        <Menu.Item key="dashboard" >
          <Link to="/" >
            <Icon type="home" />
            <span>{translate("menu.dashboard")}</span>
          </Link>
        </Menu.Item>
        {(menu) && menu.map(function(props, index){
          if (props.parent === "0" && !props.url) { 
            return  (
            <SubMenu key={props._id} title={<span><Icon type={props.icon} /><span>{translate(props.text)}</span></span>}>
              <SubMenuItems items={menu} subMenuKey={props.text} parent={props._id} translate={translate} level={1} />
            </SubMenu>
            )} 
          if (props.parent === "0" && props.url) {
            
            return (
              <Menu.Item key={props._id} >
                <Link to={props.url}>
                  <Icon type={props.icon} />
                  <span>{translate(props.text)}</span>
                </Link>
              </Menu.Item>
            );
          }}
        )}
      </Menu>
    );
  }
}

const mapStateToProps = (state) =>  ({
  translate: getTranslate(state.localize)
})
  
export default connect(mapStateToProps)(withRouter(DrawerMenu));