import React, { Component } from 'react';
import { BrowserRouter,  Route, Switch } from "react-router-dom";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import LoginLayout from './layouts/LoginLayout';
import LoginPage from "./login/LoginPage";
import {AppRoute} from "./common/utils"
import NotFound from "./common/NotFound";
import { connect } from "react-redux";
import TopLoadingComponent  from "./common/TopLoader";
import MainLayout from "./layouts/MainLayout"

const el = require('./locales/el.json');
const en = require('./locales/en.json');
const onMissingTranslation = ({ defaultTranslation, translationId, languageCode }) => translationId;
class App extends Component {

  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: "Greek", code: "el" },
        { name: "English", code: "en" } 
      ],
      options: { 
        renderToStaticMarkup,
        onMissingTranslation,
        defaultLanguage: localStorage.getItem('locale') || "el"
      }
    });

    this.props.addTranslationForLanguage(el, "el");
    this.props.addTranslationForLanguage(en, "en");
  }

  state = {
    menu: null,
    loading: true
  };



  componentDidMount(){
    this.setState({loading:false})
  }


  render() {
    
    if (this.state.loading) {
      return <TopLoadingComponent />;
    }
    
    return (
      <BrowserRouter>
        <Switch>
          <AppRoute path="/login" layout={LoginLayout} component= {LoginPage} exact/>
          <MainLayout />
          <Route component= {NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
      auth: state.auth
  };
};

export default connect(mapStateToProps)(withLocalize(App));