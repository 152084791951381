import React from 'react';
import { Route } from "react-router-dom";

export const AppRoute = ({ component: Component, lazy, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => {
    return lazy ? <Layout/> : (
      <Layout>
        <Component {...props} />
      </Layout>
    )
  }} />
)
