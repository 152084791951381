// printReducer.js
import { SET_PRINTSYSTEM_CONNECTED, SET_PRINTTERS } from '../actions/types';

const initialState = {
  isConnected: false,
  printers: [],
  default: null
}

export default function(state = initialState, action ) {
  switch(action.type) {
    case SET_PRINTSYSTEM_CONNECTED:
      var update = {...state, isConnected: action.payload}
      if (action.payload === false) {
        update.printers = []
        update.default = null
      }
      return update
    case SET_PRINTTERS:
      var defaultPrinter = action.payload && action.payload.length > 0 
        ? action.payload.filter(item => item.isDefault)
        : null
      if (defaultPrinter) defaultPrinter = defaultPrinter[0]
      return {
        ...state,
        printers: action.payload,
        default: defaultPrinter
      }
    default: 
      return state;
  }
}