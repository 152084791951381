import axios from 'axios';
import { GET_ERRORS, SET_CURRENT_USER } from './types';
import jwt_decode from 'jwt-decode';

export const registerUser = (user, history) => dispatch => {
    axios.post('/api/v1/register', user)
    .then(res => history.push('/login'))
    .catch(err => {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        });
    });
}

export const loginUser = (user) => dispatch => {
    axios.post('/api/v1/login', user)
    .then(res => {
        
        if (!res.data){
            if (res.response.data)dispatch(setError(res.response.data))
        } else {
            const { token } = res.data;
            localStorage.setItem('key', token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        }
    })
}

export const setCurrentUser = decoded => {
    
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}

export const logoutUser = (history) => dispatch => {
    
    axios.get('/api/v1/logout')
    .then(res => {
        localStorage.removeItem('key');
        dispatch(setCurrentUser({}));    
    })
    .catch(err => {
        dispatch(setError(err.response.data))
    });
   
}

export const setError = error =>{
    return {
        type: GET_ERRORS,
        payload: error
    }
}