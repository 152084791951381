import React from 'react';
import '../styles/login.css';

const LoginLayout = props => (
  <div className="container-login" style={{ backgroundImage : `url("static/img/bg-01.jpg")`}}>
    <div className="wrap-login p-l-110 p-r-110 p-t-62 p-b-33">
      {props.children}
    </div>
  </div>
)

export default LoginLayout;